@font-face {
  font-family: 'Inter_Variable';
  src: url('./../src/fonts/Inter/Inter-VariableFont.ttf');
}

@font-face {
  font-family: 'Inter_Bold';
  src: url('./../src/fonts/Inter/static/Inter-Bold.ttf');
}

// colors
$color-rose-white: #fbf5f3;
$color-cedar-chest: #c8553d;
$color-cadmium-orange: #f28f3b;
$color-unbleached-silk: #ffd5c2;
$color-steel-teal: #588b8b;
$color-charcoal: #424242;
$color-black: #000;
$color-grey: #999;
$color-white: #fff;
$color-green: #3fac23;
$color-red: $color-cedar-chest;

// font sizes
$font-size-xxl: 30px;
$font-size-xl: 28px;
$font-size-l2: 22px;
$font-size-l: 24px;
$font-size-ll: 25px;
$font-size-sl: 18px;
$font-size-default: 16px;
$font-size-s: 14px;
$font-size-xs: 10px;

//button
$border-radius: 30px;
$button-width: 200px;
// centered content width
$container-width: 900px;

:root {
  //page background color
  --page-background-color: $color-black;
}

@mixin outline-border {
  outline: none !important;
  border: 1px solid $color-steel-teal;
}

@mixin bold {
  font-family: Inter_Bold;
  font-weight: bold;
}

#root {
  height: 100%;
}

html,
body {
  height: 100%;
  background-color: $color-white;
}

body {
  font-family: 'Inter_Variable', sans-serif;
  font-size: $font-size-default;
  background-color: var(--page-background-color);
  margin: 0;
  min-height: 100%;

  .container-center {
    margin: 0 auto;
    max-width: $container-width;
    padding: 30px;
  }
}

.home {
  @include bold;
  background-color: $color-white;
  color: $color-black;
  font-size: $font-size-l2;
  line-height: 1.3em;
  /*padding: 30px;*/
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  height: 100%;
  font-family:'Inter_Variable';
  text-align:center;
  .logo-container {
    /*padding-top: 26px;
    display: flex;
    justify-content: center;*/
  }
}

.layout {
  background-color: $color-rose-white;
  color: $color-black;
  text-align: center;
  padding-top: 20px;
}
.layout-black {
  background-color: #fff;
  color: #000;
  text-align: center;
  padding-top: 20px;
  height:100%;
}
button {
  padding: 19px;
  margin-top: 16px;
  width: 100%;
  font-size: $font-size-default;
  background-color: $color-white;
  border: none;
  color: $color-charcoal;
  transition-duration: 0.4s;

  &.button--skip-question {
    color: $color-cedar-chest;
    background-color: transparent;
    text-decoration: underline;
  }

  &.button--submit {
    color: $color-white;
    background-color: $color-cadmium-orange;
    text-align: center;
    width: $button-width;
    border-radius: $border-radius;
    margin-bottom: 15px;
    img {
      float: right;
    }
  }

  &.button--start {
    color: $color-white;
    background-color: $color-steel-teal;
    text-align: center;
    font-size: $font-size-default;
    line-height: 19px;
    border-radius: $border-radius;
    width: $button-width;
  }

  &.button--correct {
    color: $color-rose-white;
    background-color: $color-steel-teal;
  }

  &.button-wrong {
    color: $color-rose-white;
    background-color: $color-cedar-chest;
    text-decoration: line-through;
  }

  // commented out as it is not working on mobile.
  // TODO: https://stackoverflow.com/questions/17233804/how-to-prevent-sticky-hover-effects-for-buttons-on-touch-devices
  //&:hover, &:active, &:focus {
  // background-color: $color-steel-teal;
  //  color: $color-white;
  //  border:none;
  //}

  &:focus {
    @include outline-border;
  }
}

a {
  color: $color-cedar-chest;

  &:focus {
    @include outline-border;
  }
}

.questions .form p {
  color: #000;
  font-size: 20px;
  line-height: 28px;
  font-family: 'Inter_Variable';
  font-weight: bold;
}
.questions .form .form-feedback p {
  font-family: 'Inter_Variable';
  font-size: 14px;
  color: red;
}
.layout-black footer p {
  color: #000;
}
input {
  width: 100%;
  height: 56px;
  padding: 0;
  border: solid 1px #5f5f5f;
  color: $color-black;
  font-size: $font-size-default;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-align: center;

  & + & {
    margin-top: 15px;
  }

  &::placeholder {
    color: $color-grey;
  }

  &:focus {
    @include outline-border;
  }
}

.questions {
  .progress {
    display: flex;
    justify-content: center;
    padding: 10px 0;

    .final {
      margin-left: -30px;
    }
  }

  h1 {
    @include bold;
    text-align: left;
    font-size: $font-size-l2;
    line-height: 28px;
    color: $color-charcoal;
  }

  .text {
    &--green {
      color: $color-green;
    }

    &--red {
      color: $color-red;
    }

    &--bold {
      @include bold;
    }
  }

  p {
    font-size: $font-size-s;
    color: $color-charcoal;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .form {
    text-align: center;
  }
}

.thank-you {
  &-text {
    @include bold;
    font-size: $font-size-l;
    line-height: $font-size-l;
    text-align: center;
  }

  &-feedback {
    font-size: $font-size-xs;
    line-height: 12px;
    text-align: center;
  }

  &-image {
    width: 80%;

    @media screen and (min-width: 400px) {
      width: 250px;
    }
  }
}

.center {
  display: flex;
  justify-content: center;

  .the-answer {
    font-size: $font-size-l2;
  }
  .description-answer {
    font-size: $font-size-l2;
    text-align: left;
  }
}

.source-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-feedback p {
  color: red;
}
footer {
  $padding: 20px;
  border-top: 1px solid #e1e1e1;
  color: $color-black;
  font-size: $font-size-xs;
  line-height: 12px;
  margin: $padding -$padding 0 -$padding;
  padding-top: $padding;
  /*display: flex;
  justify-content: center;*/
}
